import * as React from "react"

import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import NarrowContent from "../components/visualContainers/narrowContent"
import PageTitle from "../components/contentComponents/pageTitle"
import { H2, P } from "../components/contentComponents/text"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { Buttons } from "../components/contentComponents/button"
import Wall from "../components/visualComponents/wall"
import { useState } from "react"

const ContactPage = (props) => {
  const t = props.pageContext.pageData
  const { site, locale, pageName, fullPath } = props.pageContext

  const [agreed, setAgreed] = useState(false)
  const [formSent, setFormSent] = useState(false)
  const [formFailed, setFormFailed] = useState(false)

  return (
    <Layout site={site} language={locale} pagePath={props.path} pageName={pageName}>
      <Seo title={t.pageTitle} lang={locale} pagePath={fullPath} site={site} meta={t.seo} />
      <NarrowContent>
        <PageTitle title={t.pageTitle} />

        <Formik
          initialValues={{y_name: "", m_name: "", c_country: "", w_url: "", email: "", phone: "", s_country: "", agree: false}}
          onSubmit={(values, { setSubmitting }) => {
            const leadForm = document.getElementById('lead-form')
            const submitUrl = 'https://script.google.com/macros/s/AKfycbxBFT8FK-AG9D0-vfPbc9C6iOO9K2YwjE1UIM01pKS36KEAJE1mj2_m11y2rifS6kPRjQ/exec'
            fetch(submitUrl, {method: 'POST', body: new FormData(leadForm)})
              .then(() => {
                setFormSent(true)
                setSubmitting(false)
              })
              .catch(error => {
                setFormFailed(true)
                setSubmitting(false)
              })
              .finally(() => {
                leadForm.style.cursor = 'initial'
                alert.hidden = false
              })
          }}
          validate={values => {
            const errors = {};
            if (!values.email) {
              errors.email = t.errorMessages.email;
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = t.errorMessages.emailInvalid;
            }
            if(!values.agree) {
              errors.agree = t.errorMessages.agreement
            }
            if (!values.name) {
              errors.name = t.errorMessages.yourName
            }
            if (!values.merchant) {
              errors.merchant = t.errorMessages.merchantName
            }
            return errors;
          }}
          enableReintialize={true}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <StyledForm id="lead-form">
              <Wall id={"wall1"} hideMobile={true} height={40} position="left: -70px; top: 106px;"/>
              <Wall id={"wall2"} hideMobile={true} height={40} position="left: -40px; top: 106px;"/>
              <Wall id={"wall3"} hideMobile={true} height={50} position="right: -70px; top: 630px;"/>
              <Wall id={"wall4"} hideMobile={true} height={50} position="right: -70px; top: 550px;"/>

              <H2 strong={true} mt={40} mb={40}>{t.title}</H2>
              <FieldRow>
                <label htmlFor="name"><LabelText>{t.formFields.yourName}</LabelText></label>
                <StyledField type="text" name="name" />
                <StyledErrorMessage name="name" component="div" />
              </FieldRow>
              <FieldRow>
                <label htmlFor="merchant"><LabelText>{t.formFields.merchantName}</LabelText></label>
                <StyledField type="text" name="merchant" />
                <StyledErrorMessage name="merchant" component="div" />
              </FieldRow>
              <FieldRow>
                <label htmlFor="country"><LabelText>{t.formFields.merchantCountry}</LabelText></label>
                <StyledField type="text" name="country" />
                <StyledErrorMessage name="country" component="div" />
              </FieldRow>
              <FieldRow>
                <label htmlFor="store-url"><LabelText>{t.formFields.storeURL}</LabelText></label>
                <StyledField type="url" name="store-url" />
                <StyledErrorMessage name="store-url" component="div" />
              </FieldRow>
              <FieldRow>
                <label htmlFor="email"><LabelText>{t.formFields.email}</LabelText></label>
                <StyledField type="email" name="email" />
                <StyledErrorMessage name="email" component="div" />
              </FieldRow>
              <FieldRow>
                <label htmlFor="phone"><LabelText>{t.formFields.phone}</LabelText></label>
                <StyledField type="tel" name="phone" placeholder={t.formFields.phonePlaceholder} />
                <StyledErrorMessage name="phone" component="div" />
              </FieldRow>
              <FieldRow>
                <label htmlFor="markets"><LabelText>{t.formFields.targetCountries}</LabelText></label>
                <StyledField type="text" name="markets" />
                <StyledErrorMessage name="markets" component="div" />
              </FieldRow>
              <FieldRow>
                <CheckboxLabel htmlFor="agree">
                  <Field type="hidden" name="agree" id="agree" value={values.agree} />
                  <Checkbox onClick={() => {
                    setAgreed(!values.agree)
                    setFieldValue('agree', !values.agree)
                  }}>
                    {agreed && <CheckboxChecked />}
                  </Checkbox>
                  <P strong={true}>{t.formFields.agreeTo}</P>
                </CheckboxLabel>
                <StyledErrorMessage name="agree" component="div" />
              </FieldRow>
              <P addMargins={true} mb={40}>{t.formFields.dataDisclaimer}</P>
              <Buttons className="center-vertical">
                {formSent && <P center={true} strong={true}>{t.successMessage}</P>}
                {!formSent && (
                  <SubmitButton type="submit" disabled={isSubmitting}>
                    {isSubmitting ? t.formFields.submitButtonSending : t.formFields.submitButton}
                  </SubmitButton>
                )}
                {formFailed && <FormErrorMessage>{t.errorMessages.submitError}</FormErrorMessage>}
              </Buttons>
            </StyledForm>
          )}
        </Formik>
      </NarrowContent>
    </Layout>
  )
}

export default ContactPage


const FieldRow = styled.div`
  width: 100%;
`

const StyledField = styled(Field)`
  position: relative;
  z-index: 4;
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  background: #FFFFFF;
  box-shadow: -20px 5px 25px rgba(16, 49, 114, 0.08), -20px 15px 30px rgba(16, 79, 114, 0.06);
  border: none;
  font-size: 15px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
`

const SubmitButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 32px;
  border: none;
  border-radius: 2px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  text-transform: uppercase;
  color: #000000;
  text-decoration: none;
  z-index: 50;
  background: #B0ECFB;  
  box-shadow: 0 20px 30px rgba(42, 56, 94, 0.1);
  margin-bottom: ${props => props.mb ? props.mb : 0}px;
  margin-top: ${props => props.mt ? props.mt : 0}px;
`

const StyledForm = styled(Form)`
  position: relative;
  max-width: 600px;
`

const LabelText = styled.p`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 140%;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 4px;
`

const CheckboxLabel = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  
  input {
    display: none;
  }
`

const Checkbox = styled.div`
  position: relative;
  z-index: 4;
  width: 40px;
  height: 40px;
  padding: 8px;
  background: #FFFFFF;
  box-shadow: -20px 5px 25px rgba(16, 49, 114, 0.08), -20px 15px 30px rgba(16, 79, 114, 0.06);
  flex: 0 0 auto;
  margin-right: 20px;
  border-radius: 2px;
`

const CheckboxChecked = styled.div`
  width: 24px;
  height: 24px;
  background: #B0ECFB;
  border-radius: 2px;
`

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 8px;
  font-family: Roboto, sans-serif;
  color: #FF5F2D;
`

const FormErrorMessage = styled(P)`
  margin-top: 8px;
  color: #FF5F2D;
`